







































































































































import { Vue, Component } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report/';
import { ReportTypeNames } from "@/constants";
@Component({})
export default class AuditScoreResultsTableHeader extends Vue {
  store = reportStores.details;

  created() {
    this.store.fetchById();
  }

  get report() {
    return this.store.report;
  }

  get isWaterReport(){
    return this.report.reportType.name === ReportTypeNames.water;
  }

  get isInjectableReport(){
    return this.report.reportType.name === ReportTypeNames.injectable;
  }

  get isHatcheryReport(){
    return this.report.reportType.name === ReportTypeNames.hatchery;
  }

  get isCAndDReport(){
    return this.report.reportType.name === ReportTypeNames.cnd;
  }

  get isIpmReport(){
    return this.report.reportType.name === ReportTypeNames.ipm;
  }

  get isLabOnWheelReport(){
    return this.report.reportType.name === ReportTypeNames.labOnWheel;
  }

  get isEbaReport(){
    return this.report.reportType.name === ReportTypeNames.eba;
  }

  get isPeaReport(){
    return this.report.reportType.name === ReportTypeNames.pea;
  }

}
