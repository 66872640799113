



















import { Vue, Component } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report/';
import { ReportDetailsResponse } from '@/models/poultry/report.model';

@Component({
  components: {},
})
export default class SwabSamplesResultsTableBody extends Vue {
  store = reportStores.details;

  created() {
    this.store.fetchById();
  }

  getColorClassByScore(score: string) {
    if (score === "TNTC")
      return "red";
    const temp = parseInt(score);
    if (temp >= 0 && temp <= 40)
      return "green";
    else if (temp > 40 && temp <= 120)
      return "orange";
    else
      return "red";
  }

  getAssessmentByScore(score: string) {
    if (score === "TNTC")
      return "5";
    const temp = parseInt(score);
    if (temp === 0)
      return "0";
    else if (temp > 0 && temp <= 40)
      return "1";
    else if (temp > 40 && temp <= 120)
      return "2";
    else if (temp > 120 && temp <= 400)
      return "3";
    else if (temp > 400)
      return "4";
  }

  get report(): ReportDetailsResponse | null {
    return this.store.report;
  }

  displayCount(type: number[]) {
    if (type.length > 1)
      return true;
    return false;
  }

  // Put spaces between capital letter
  reformatText(summaryTypeKey: string) {
    switch (summaryTypeKey) {
      case "floors":
        return "House floors (cracks)";
      case "airInlet":
        return "Air inlet";
      case "feedingSystem":
        return "Feeding system";
      case "drinkingSystem":
        return "Drinking system (pipe & drink cup)";
      case "walls":
        return "House walls (cracks, corners)";
      case "ceiling":
        return "House ceiling (cracks, corners)";
      case "hopper":
        return "Hopper";
      case "eggStorageRoom":
        return "Egg storage room (floor)";
      case "packingArea":
        return "Packing area";
      case "eggBelt":
        return "Egg belt";
      case "cageFloor":
        return "Cage/nest floor";
      case "cageSides":
        return "Cage/nest sides";
    }
    return summaryTypeKey.replace(/([A-Z])/g, ' $1').trim();
  }
}
