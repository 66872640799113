import { ClientUpdateRequestBody } from '@/models/poultry/client.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ClientResponse } from '@/models/poultry/client.model';
import { Endpoints } from '../endpoints';
import store from '../index';

export interface ClientDetailsStoreInterface {
  response: ClientResponse | null;
  isLoading: boolean;
  error: ErrorResponse | null;
  fetchById: (id: number) => void;
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'client.details',
  store
})
class ClientDetailsStore extends VuexModule {
  isLoading = false;
  isUpdated = false;
  isDeleted = false;
  error: ErrorResponse | null = null;
  client = {} as ClientUpdateRequestBody;
  clientId: number | string = '';
  species = '';

  @Mutation
  updateClient(params: ClientUpdateRequestBody) {
    this.client = params;
  }

  @Mutation
  setClientId(params: number) {
    this.clientId = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setUpdateFlag(params: boolean) {
    this.isUpdated = params;
  }

  @Mutation
  setDeleteFlag(params: boolean) {
    this.isDeleted = params;
  }
  
  @Mutation
  setSpecies(params: string) {
    this.species = params;
  }

  @Action
  async fetchById(id: string | number) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/${id}`, {
        params: {
          species: this.species,
        },
      });

      this.context.commit('updateClient', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async delete() {
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.delete(`${Endpoints.Clients}/${this.clientId}`);
      this.context.commit('setDeleteFlag', true);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async update() {
    this.context.commit('setUpdateFlag', false);
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.put(`${Endpoints.Clients}/${this.clientId}`, {
        addressLine1: this.client.addressLine1,
        addressLine2: this.client.addressLine2,
        cityId: Number(this.client.cityId),
        clientCode: this.client.clientCode,
        countryId: Number(this.client.countryId),
        id: this.client.id,
        name: this.client.name,
        phoneNumber: this.client.phoneNumber,
        postcode: this.client.postcode,
        species: this.species,
        stateId: Number(this.client.stateId),
        regionId: Number(this.client.regionId),
      });
      this.context.commit('setUpdateFlag', true);
      this.fetchById(this.clientId)
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ClientDetailsStore);
