






































































































import { Vue, Component } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import AuditScoreResultsTable from './AuditScoreResultsTable.vue';
import SwabSamplesResultsTable from './CleaningAndDisinfection/SwabSamplesResultsTable.vue';
import AggregatedResultsTable from './LabOnWheel/AggregatedResultsTable.vue';
import EbaAggregatedResultsTable from './EggBreakOut/EbaAggregatedResultsTable.vue';
import PeaAggregatedResultsTable from './PippedEmbryoAnalysis/PeaAggregatedResultsTable.vue';
import { reportStores } from '@/store/poultry/report/';
import { ReportDetailsResponse, PeaReportDetailsResponse } from '@/models/poultry/report.model';
import Modal from '@/components/poultry/Modal.vue';
import DeleteReport from '../DeleteReport.vue';
import { downloadReportById } from '@/store/poultry/report/report.download';
import dayjs from 'dayjs';
import { ReportTypeNames, DownloadDocumentType } from "@/constants";

@Component({
  components: {
    Page,
    AuditScoreResultsTable,
    Modal,
    DeleteReport,
    SwabSamplesResultsTable,
    AggregatedResultsTable,
    EbaAggregatedResultsTable,
    PeaAggregatedResultsTable
  }
})
export default class AuditScoreResults extends Vue {
  store = reportStores.details;
  list = reportStores.list;
  showDeleteModal = false;

  created() {
    this.store.setReportId(Number(this.$route.params.id));
    this.store.fetchById();
    reportStores.details.resetReportIds();
  }

  get farmName(): string {
    if (this.report.farm && this.report.farm.name) {
      return this.report.farm.name;
    }

    return '';
  }

  get farmCreationDate(): string {
    if (!this.report.dateOfAudit) {
      return '-'
    }

    return dayjs(this.report.dateOfAudit).format('DD MMM YYYY')
  }

  get report(): ReportDetailsResponse | PeaReportDetailsResponse {
    return this.store.report;
  }

  get reportId() {
    return Number(this.store.reportId);
  }

  get isWaterReport(){
    return this.report.reportType.name === ReportTypeNames.water;
  }

  get isInjectableReport(){
    return this.report.reportType.name === ReportTypeNames.injectable;
  }

  get isHatcheryReport(){
    return this.report.reportType.name === ReportTypeNames.hatchery;
  }

  get isCAndDReport(){
    return this.report.reportType.name === ReportTypeNames.cnd;
  }

  get isIpmReport(){
    return this.report.reportType.name === ReportTypeNames.ipm;
  }

  get isLabOnWheelReport(){
    return this.report.reportType.name === ReportTypeNames.labOnWheel;
  }

  get isEbaReport(){
    return this.report.reportType.name === ReportTypeNames.eba;
  }

  get isPeaReport(){
    return this.report.reportType.name === ReportTypeNames.pea;
  }

  handleExportExcelClicked() {
    downloadReportById(this.reportId, DownloadDocumentType.excel);
  }

  handleExportPdfClicked(){
    downloadReportById(this.reportId, DownloadDocumentType.pdf);
  }

  handleDeleteReport() {
    this.showDeleteModal = true;
    this.store.addReportId(this.reportId);
  }

  handleHouseType(houseTypeId: number) {
    switch (houseTypeId) {
      case 2:
        return "Broiler";
      case 1:
        return "Layer / Breeder";
    }
  }
}
