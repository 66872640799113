
































import { Component, Vue, Prop } from 'vue-property-decorator';
import PeaAggregatedResultsTableBody from './PeaAggregatedResultsTableBody.vue';
import { ReportDetailsResponse, PeaReportDetailsResponse } from '@/models/poultry/report.model';


@Component({
  components: {
    PeaAggregatedResultsTableBody
  }
})

export default class PeaAggregatedResultsTable extends Vue {
    @Prop()
    report!: ReportDetailsResponse | PeaReportDetailsResponse;
}
