






























import { Component, Vue, Prop } from 'vue-property-decorator';
import AggregatedResultsTableBody from './AggregatedResultsTableBody.vue';
import RiskLevelScheme from './RiskLevelScheme.vue';
import { ReportDetailsResponse } from '@/models/poultry/report.model';


@Component({
  components: {
    AggregatedResultsTableBody,
    RiskLevelScheme
  }
})

export default class AggregatedResultsTable extends Vue {
    @Prop()
    report!: ReportDetailsResponse | null;
}
