































import { Vue, Component } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report/';
import SwabSamplesResultsTableBody from './SwabSamplesResultsTableBody.vue';
import Interpretation from './Interpretation/Interpretation.vue';

const assessmentToColorClass: {
  [key: string]: string;
} = {
  Good: 'good',
  Bad: 'bad',
  Redo: 'redo',
};

@Component({
  components: {
    SwabSamplesResultsTableBody,
    Interpretation
  }
})
export default class SwabSamplesResultsTable extends Vue {
  store = reportStores.details;

  get score() {
    return this.store.report.sampleScore;
  }

  getColorClass(assessment: string) {
    return assessmentToColorClass[assessment];
  }

  getAssessment(score: number){
    if (score < 1.5)
      return "Good";
    else if (score >= 1.5 && score <= 3.0)
      return "Bad";
    return "Redo";
  }
}
