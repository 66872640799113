
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportDetailsResponse } from '@/models/poultry/report.model';

@Component({
  components: {},
})
export default class AggregatedResultsTableBody extends Vue {

    @Prop()
    report!: ReportDetailsResponse | null;

    getColorClassByPercentage(percentage: number) {
        
        if (percentage >= 80)
            return "green";
        else if (percentage >= 60 && percentage <= 79)
            return "orange";
        else
            return "red";
    }
}
