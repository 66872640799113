


















































































































































import { Vue, Component } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report/';
import { ReportDetailsResponse, PeaReportDetailsResponse } from '@/models/poultry/report.model';
import { LesionsScoreSummary, TrayLesionsScores } from '@/models/poultry/eggBreakOut.model';
import { PEALesionScore, HatcherSummary, PEAHatcherSummary, FormattedPEAHatcherSummary, FormattedPEAResult } from '@/models/poultry/pippedEmbryoAnalysis.model';
import { ReportTypeNames } from "@/constants";

const assessmentToColorClass: {
  [key: string]: string;
} = {
  Good: 'good',
  'Need To Improve': 'need-to-improve',
  Poor: 'poor',
};

@Component({
  components: {},
})
export default class AuditScoreResultsTableBody extends Vue {
  store = reportStores.details;

  created() {
    this.store.fetchById();
  }

  getColorClass(assessment: string) {
    return assessmentToColorClass[assessment];
  }

  get report(): ReportDetailsResponse | PeaReportDetailsResponse{ 
    return this.store.report;
  }

  get isWaterReport(){
    return this.report.reportType.name === ReportTypeNames.water;
  }

  get isInjectableReport(){
    return this.report.reportType.name === ReportTypeNames.injectable;
  }

  get isHatcheryReport(){
    return this.report.reportType.name === ReportTypeNames.hatchery;
  }

  get isCAndDReport(){
    return this.report.reportType.name === ReportTypeNames.cnd;
  }

  get isIpmReport(){
    return this.report.reportType.name === ReportTypeNames.ipm;
  }

  get isLabOnWheelReport(){
    return this.report.reportType.name === ReportTypeNames.labOnWheel;
  }

  get isEbaReport(){
    return this.report.reportType.name === ReportTypeNames.eba;
  }

  get isPeaReport(){
    return this.report.reportType.name === ReportTypeNames.pea;
  }

  formatLesionsScoreSummaries(lesionScoreSummary: LesionsScoreSummary): TrayLesionsScores[]{
    return [
      { keyName: "EARLY", score: lesionScoreSummary.early.score, maxScore: lesionScoreSummary.early.maxScore, percentage: lesionScoreSummary.early.percentage },
      { keyName: "MID", score: lesionScoreSummary.mid.score, maxScore: lesionScoreSummary.mid.maxScore, percentage: lesionScoreSummary.mid.percentage },
      { keyName: "LATE", score: lesionScoreSummary.late.score, maxScore: lesionScoreSummary.late.maxScore, percentage: lesionScoreSummary.late.percentage },
      { keyName: "PIP", score: lesionScoreSummary.pip.score, maxScore: lesionScoreSummary.pip.maxScore, percentage: lesionScoreSummary.pip.percentage },
      { keyName: "THIN", score: lesionScoreSummary.thin.score, maxScore: lesionScoreSummary.thin.maxScore, percentage: lesionScoreSummary.thin.percentage },
      { keyName: "USD", score: lesionScoreSummary.usd.score, maxScore: lesionScoreSummary.usd.maxScore, percentage: lesionScoreSummary.usd.percentage },
      { keyName: "BAC", score: lesionScoreSummary.bac.score, maxScore: lesionScoreSummary.bac.maxScore, percentage: lesionScoreSummary.bac.percentage },
      { keyName: "FUN", score: lesionScoreSummary.fun.score, maxScore: lesionScoreSummary.fun.maxScore, percentage: lesionScoreSummary.fun.percentage },
      { keyName: "CRA", score: lesionScoreSummary.cra.score, maxScore: lesionScoreSummary.cra.maxScore, percentage: lesionScoreSummary.cra.percentage },
      { keyName: "ANA", score: lesionScoreSummary.ana.score, maxScore: lesionScoreSummary.ana.maxScore, percentage: lesionScoreSummary.ana.percentage }
    ]
  }

  getAirsacStyleColorByPercentage(percentage: number): string {
    let style = 'poor';

    if (percentage < 1){
      style = 'good';
    }else if (percentage >= 1 && percentage <= 10){
      style = 'regular';
    }

    return style;
  }

  getHeartStyleColorByPercentage(percentage: number): string {
    let style = 'good';

    if (percentage > 10){
      style = 'poor';
    }

    return style;
  }

  getLiverAndYolkStyleColorByPercentage(percentage: number): string{
    let style = 'good';

    if (percentage != 0){
      style = 'need-to-improve';
    }

    return style;
  }

  getGizzardStyleColorByPercentage(percentage: number): string{
    let style = 'good';

    if (percentage > 25){
      style = 'poor';
    }

    return style;
  }

  formatPeaLesionsScoreSummaries(lesionsScore: PEALesionScore): HatcherSummary[]{
    return [
      { keyName: "Airsac", score: lesionsScore.airsac.score, maxScore: lesionsScore.airsac.maxScore, percentage: lesionsScore.airsac.percentage, assessment: lesionsScore.airsac.assessment, styleColor: this.getAirsacStyleColorByPercentage(lesionsScore.airsac.percentage) },
      { keyName: "Heart", score: lesionsScore.heart.score, maxScore: lesionsScore.heart.maxScore, percentage: lesionsScore.heart.percentage, assessment: lesionsScore.heart.assessment, styleColor: this.getHeartStyleColorByPercentage(lesionsScore.heart.percentage)  },
      { keyName: "Liver", score: lesionsScore.liver.score, maxScore: lesionsScore.liver.maxScore, percentage: lesionsScore.liver.percentage, assessment: lesionsScore.liver.assessment, styleColor: this.getLiverAndYolkStyleColorByPercentage(lesionsScore.liver.percentage)  },
      { keyName: "Gizzard", score: lesionsScore.gizzard.score, maxScore: lesionsScore.gizzard.maxScore, percentage: lesionsScore.gizzard.percentage, assessment: lesionsScore.gizzard.assessment, styleColor: this.getGizzardStyleColorByPercentage(lesionsScore.gizzard.percentage)  },
      { keyName: "Yolk", score: lesionsScore.yolk.score, maxScore: lesionsScore.yolk.maxScore, percentage: lesionsScore.yolk.percentage, assessment: lesionsScore.yolk.assessment, styleColor: this.getLiverAndYolkStyleColorByPercentage(lesionsScore.yolk.percentage)  }
    ]
  }

  get peaLesionsScoreSummaries(): FormattedPEAResult{
    const hatchers: PEAHatcherSummary[] = this.report.summary.results.hatchers;
    
    const hatcherSummaries: FormattedPEAHatcherSummary[] = hatchers.map(hatcher => {
      return {
        hatcherName: hatcher.hatcherName,
        hatcherId: hatcher.hatcherId,
        lesionsScores : this.formatPeaLesionsScoreSummaries(hatcher.lesionsScore)
      }
    });

    return {
      hatcheryName: this.report.summary.results.hatcheryName,
      hatchers: hatcherSummaries,
      aggregatedResults: this.formatPeaLesionsScoreSummaries(this.report.summary.results.aggregatedResults)
    };
  }


  // Put spaces between capital letter
  reformatText(summaryTypeKey: string) {
    let currentString = '';
    switch (summaryTypeKey) {
      case "postVaccination": {
        currentString = "Post-Vaccination";
        break;
      }
      case "fliesExcrements": {
        currentString = "Flies Excrements, Vomits Spots & Additional Risk Points";
        break;
      }
      case "feedStorageAreaAndBarns": {
        currentString = "Feed Storage Area & Barns";
        break;
      }
      case "presenceOfMobileFormsInOtherAreaOrStructures": {
        currentString = "Presence of Mobile Forms in Other Area / Structures";
        break;
      }
      case "ventilationSystem": {
        currentString = "Ventilation system – presence of dust";
        break;
      }
      case "drinkingSystem": {
        currentString = "Drinking system – Stained";
        break;
      }
      case "feedingSystem": {
        currentString = "Feeding system – presence of dust or feed waste";
        break;
      }
      case "housePresenceOfDust": {
        currentString = "House – presence of dust or manure tracks or stained";
        break;
      }
      case "housePestControl": {
        currentString = "House - Pest Control ";
        break;
      }
      case "eggGathering": {
        currentString = "Egg gathering system – presence of dust or wasted eggs";
        break;
      }
      case "manureDisposal": {
        currentString = "Manure disposal system - presence of manure tracks";
        break;
      }
      case "cagesNest": {
        currentString = "Cages or nests – presence of dust, feathers, droppings";
        break;
      }
      default: {
        let formattedString = summaryTypeKey.replace(/([A-Z])/g, ' $1').trim();
        currentString = formattedString[0].toUpperCase() + formattedString.substring(1);
        break;
      }
    }
    return currentString;
  }
}
