








import { Vue, Component } from 'vue-property-decorator';
import AuditScoreResultsTableHeader from './AuditScoreResultsTableHeader.vue';
import AuditScoreResultsTableBody from './AuditScoreResultsTableBody.vue';

@Component({
  components: {
    AuditScoreResultsTableHeader,
    AuditScoreResultsTableBody
  }
})

export default class AuditScoreResultsTable extends Vue {
}
