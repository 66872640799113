












import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportDetailsResponse, PeaReportDetailsResponse } from '@/models/poultry/report.model';
import { PEALesionScore, HatcherSummary, PEAHatcherSummary, FormattedPEAHatcherSummary, FormattedPEAResult } from '@/models/poultry/pippedEmbryoAnalysis.model';

@Component({
  components: {},
})

export default class PeaAggregatedResultsTableBody extends Vue {

    @Prop()
    report!: ReportDetailsResponse | PeaReportDetailsResponse;

    getAirsacStyleColorByPercentage(percentage: number): string {
    let style = 'poor';

    if (percentage < 1){
      style = 'good';
    }else if (percentage >= 1 && percentage <= 10){
      style = 'regular';
    }

    return style;
  }

  getHeartStyleColorByPercentage(percentage: number): string {
    let style = 'good';

    if (percentage > 10){
      style = 'poor';
    }

    return style;
  }

  getLiverAndYolkStyleColorByPercentage(percentage: number): string{
    let style = 'good';

    if (percentage != 0){
      style = 'need-to-improve';
    }

    return style;
  }

  getGizzardStyleColorByPercentage(percentage: number): string{
    let style = 'good';

    if (percentage > 25){
      style = 'poor';
    }

    return style;
  }

  formatPeaLesionsScoreSummaries(lesionsScore: PEALesionScore): HatcherSummary[]{
    return [
      { keyName: "Airsac", score: lesionsScore.airsac.score, maxScore: lesionsScore.airsac.maxScore, percentage: lesionsScore.airsac.percentage, assessment: lesionsScore.airsac.assessment, styleColor: this.getAirsacStyleColorByPercentage(lesionsScore.airsac.percentage) },
      { keyName: "Heart", score: lesionsScore.heart.score, maxScore: lesionsScore.heart.maxScore, percentage: lesionsScore.heart.percentage, assessment: lesionsScore.heart.assessment, styleColor: this.getHeartStyleColorByPercentage(lesionsScore.heart.percentage)  },
      { keyName: "Liver", score: lesionsScore.liver.score, maxScore: lesionsScore.liver.maxScore, percentage: lesionsScore.liver.percentage, assessment: lesionsScore.liver.assessment, styleColor: this.getLiverAndYolkStyleColorByPercentage(lesionsScore.liver.percentage)  },
      { keyName: "Gizzard", score: lesionsScore.gizzard.score, maxScore: lesionsScore.gizzard.maxScore, percentage: lesionsScore.gizzard.percentage, assessment: lesionsScore.gizzard.assessment, styleColor: this.getGizzardStyleColorByPercentage(lesionsScore.gizzard.percentage)  },
      { keyName: "Yolk", score: lesionsScore.yolk.score, maxScore: lesionsScore.yolk.maxScore, percentage: lesionsScore.yolk.percentage, assessment: lesionsScore.yolk.assessment, styleColor: this.getLiverAndYolkStyleColorByPercentage(lesionsScore.yolk.percentage)  },
    ]
  }

  get peaLesionsScoreSummaries(): FormattedPEAResult{
    const hatchers: PEAHatcherSummary[] = this.report.summary.results.hatchers;
    
    const hatcherSummaries: FormattedPEAHatcherSummary[] = hatchers.map(hatcher => {
      return {
        hatcherName: hatcher.hatcherName,
        hatcherId: hatcher.hatcherId,
        lesionsScores : this.formatPeaLesionsScoreSummaries(hatcher.lesionsScore)
      }
    });

    return {
      hatcheryName: this.report.summary.results.hatcheryName,
      hatchers: hatcherSummaries,
      aggregatedResults: this.formatPeaLesionsScoreSummaries(this.report.summary.results.aggregatedResults)
    };
  }
}
