import { DEFAULT_COUNT_PER_PAGE } from '@/constants';
import { PaginationModel } from '@/models/poultry/pagination.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import arraySort from 'array-sort';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ClientResponse } from '@/models/poultry/client.model';
import { Endpoints } from '../endpoints';
import store from '../index';
import { SearchPaginatedRequest } from '@/models/poultry/requests';

export interface ListClientStoreInterface {
  response: ClientResponse | null;
  isLoading: boolean;
  error: ErrorResponse | null;
  hasClients: boolean;
  fetchAll: (params: PaginationModel) => void;
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'client.list',
  store,
})
class ListClientStore extends VuexModule {
  isLoading = false;
  isAllClients = true;
  isSearchEmpty = false;
  error: ErrorResponse | null = null;
  clients: ClientResponse[] = [];
  clientsDropdown: ClientResponse[] = [];
  pagination = { ...this.defaultPagination };
  species = ''

  currentSort: keyof ClientResponse = 'createdDate';
  currentSortOrder = { reverse: true };

  get defaultPagination(): PaginationModel {
    return {
      offset: 1,
      limit: DEFAULT_COUNT_PER_PAGE,
    };
  }

  get hasClients(): boolean {
    return this.isLoading === false && Boolean(this.clients.length);
  }

  get hasClientsDropdown(): boolean {
    return Boolean(this.clientsDropdown.length);
  }

  get sortedClients() {
    var clientsCopy = [...this.clients];

    return arraySort(clientsCopy, this.currentSort, this.currentSortOrder);
  }

  @Mutation
  setSortType(params: any) {
    if (params === this.currentSort) {
      this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
    }
    this.currentSort = params;
  }

  @Mutation
  updateClients(params: ClientResponse[]) {
    if (!params.length) {
      this.isSearchEmpty = true;
    }
    this.clients = params;
  }

  @Mutation
  updateClientsDropdown(params: ClientResponse[]) {
    this.clientsDropdown = params;
  }

  @Mutation
  updatePagination(params: PaginationModel) {
    this.pagination = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  updateAllClientsState(params: boolean) {
    this.isAllClients = params;
  }

  @Mutation
  setSpecies(params: string) {
    this.species = params;
  }

  @Action
  async fetchAll(params: PaginationModel = { offset: 1, limit: DEFAULT_COUNT_PER_PAGE }) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { offset, limit } = params;
      const { data } = await axios.get(Endpoints.Clients, {
        params: {
          offset: ((offset - 1) * limit),
          limit: limit,
          species: this.species
        },
      });

      const { clients, pagination } = data

      this.context.commit('updateClients', clients);
      this.context.commit('updatePagination', {
        ...pagination, 
        offset: ((pagination.offset) / 10) + 1
      });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchClients() {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/dropdown`,  {
        params: {
          species: this.species
        },
      });
      this.context.commit('updateClientsDropdown', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchByKeywords(params: SearchPaginatedRequest) {
    this.context.commit('updateLoadingStatus', true);

    if (!params.pagination) {
      params.pagination = {offset: 1, limit: DEFAULT_COUNT_PER_PAGE}
    }

    const { offset, limit } = params.pagination;

    try {
      const { data } = await axios.get(
        Endpoints.Clients,
        {
          params: {
            offset: ((offset - 1) * limit),
            limit: limit,
            species: this.species,
            keywords: params.keywords,
          },
        },
      );

      const { clients, pagination } = data
      
      this.context.commit('updateClients', clients);
      this.context.commit('updatePagination', {
        ...pagination, 
        offset: ((pagination.offset) / 10) + 1
      });
    } catch (error) {
      this.context.commit('setError', error);
    }

    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ListClientStore);
