


































import { Vue, Component } from 'vue-property-decorator';
@Component({})
export default class SwabSamplesResultsTable extends Vue {
  assessmentToColorClass: {
    [key: string]: string;
  } = {
    Good: 'good',
    Bad: 'bad',
    Redo: 'redo',
  };

  getScore(assessment: string){
    switch (assessment) {
      case "good":
        return "Score < 1.5";
      case "bad":
        return "1.5 ≤ Score ≤ 3.0";
      case "redo":
        return "Score > 3.0"
    }
  }
}
