











import { Component, Vue, Prop } from 'vue-property-decorator';
import { ReportDetailsResponse } from '@/models/poultry/report.model';
import { LesionsScoreSummary, TrayLesionsScores } from '@/models/poultry/eggBreakOut.model';

@Component({
  components: {},
})

export default class EbaAggregatedResultsTableBody extends Vue {

    @Prop()
    report!: ReportDetailsResponse | null;

    formatLesionsScoreSummaries(lesionScoreSummary: LesionsScoreSummary): TrayLesionsScores[]{
      return [
        {keyName: "EARLY", score: lesionScoreSummary.early.score, maxScore: lesionScoreSummary.early.maxScore, percentage: lesionScoreSummary.early.percentage},
        {keyName: "MID", score: lesionScoreSummary.mid.score, maxScore: lesionScoreSummary.mid.maxScore, percentage: lesionScoreSummary.mid.percentage},
        {keyName: "LATE", score: lesionScoreSummary.late.score, maxScore: lesionScoreSummary.late.maxScore, percentage: lesionScoreSummary.late.percentage},
        {keyName: "PIP", score: lesionScoreSummary.pip.score, maxScore: lesionScoreSummary.pip.maxScore, percentage: lesionScoreSummary.pip.percentage},
        {keyName: "THIN", score: lesionScoreSummary.thin.score, maxScore: lesionScoreSummary.thin.maxScore, percentage: lesionScoreSummary.thin.percentage},
        {keyName: "USD", score: lesionScoreSummary.usd.score, maxScore: lesionScoreSummary.usd.maxScore, percentage: lesionScoreSummary.usd.percentage},
        {keyName: "BAC", score: lesionScoreSummary.bac.score, maxScore: lesionScoreSummary.bac.maxScore, percentage: lesionScoreSummary.bac.percentage},
        {keyName: "FUN", score: lesionScoreSummary.fun.score, maxScore: lesionScoreSummary.fun.maxScore, percentage: lesionScoreSummary.fun.percentage},
        {keyName: "CRA", score: lesionScoreSummary.cra.score, maxScore: lesionScoreSummary.cra.maxScore, percentage: lesionScoreSummary.cra.percentage},
        {keyName: "ANA", score: lesionScoreSummary.ana.score, maxScore: lesionScoreSummary.ana.maxScore, percentage: lesionScoreSummary.ana.percentage}
      ]
  }
}
