








import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {
  }
})
export default class AggregatedResultsTableBody extends Vue {
  assessmentToColorClass: {
    [key: string]: string;
  } = {
    Low: 'low',
    Medium: 'medium',
    High: 'high',
  };

  getScore(assessment: string){
    switch (assessment) {
      case "low":
        return "80% ≤ Score ≤ 100%, Low Risk";
      case "medium":
        return "60% ≤ Score ≤ 79%, Medium Risk";
      case "high":
        return "0% ≤ Score ≤ 59%, High Risk"
    }   
} 
}
