import { render, staticRenderFns } from "./PeaAggregatedResultsTableBody.vue?vue&type=template&id=39c67fbe&scoped=true&"
import script from "./PeaAggregatedResultsTableBody.vue?vue&type=script&lang=ts&"
export * from "./PeaAggregatedResultsTableBody.vue?vue&type=script&lang=ts&"
import style0 from "./PeaAggregatedResultsTableBody.vue?vue&type=style&index=0&id=39c67fbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c67fbe",
  null
  
)

export default component.exports