import { Client, ClientResponse, ClientState } from '@/models/poultry/client.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { Endpoints } from '../endpoints';
import store from '../index';

export interface CreateClientStoreInterface {
  response: ClientResponse | null;
  isLoading: boolean;
  error: ErrorResponse | null;
  create: () => void;
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'client.create',
  store,
})
class CreateClientStore extends VuexModule {
  // When submitting to the API
  isLoading = false;
  // When successfully created
  isCreated = false;
  // When there's error
  error: ErrorResponse | any = null;
  // Initial State value
  protected initialState: ClientState = {
    name: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
    cityId: '',
    stateId: '',
    regionId: '',
    countryId: '',
    phoneNumber: '',
    species: ''
  };

  // Initial state initialization
  client: ClientState = { ...this.initialState };

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setCreateFlag(params: boolean) {
    this.isCreated = params;
  }

  @Mutation
  updateState(params: Client) {
    this.client = { ...params };
  }

  @Mutation
  resetClientState() {
    this.client = { ...this.initialState }
  }

  @Mutation
  setSpecies(params: string) {
    this.client.species = params
  }

  @Action
  async create(): Promise<void> {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      await axios.post<ClientState>(Endpoints.Clients, {
        ...this.client,
        cityId: Number(this.client.cityId),
        stateId: Number(this.client.stateId),
        regionId: Number(this.client.regionId),
        countryId: Number(this.client.countryId),
      });

      this.context.commit('setCreateFlag', true);
      this.context.commit('updateState', this.initialState);
    } catch (error) {
      this.context.commit('setError', error);
      this.context.commit('setCreateFlag', false);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(CreateClientStore);
