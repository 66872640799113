



























import { Component, Vue, Prop } from 'vue-property-decorator';
import EbaAggregatedResultsTableBody from './EbaAggregatedResultsTableBody.vue';
import { ReportDetailsResponse } from '@/models/poultry/report.model';


@Component({
  components: {
    EbaAggregatedResultsTableBody
  }
})

export default class EbaAggregatedResultsTable extends Vue {
    @Prop()
    report!: ReportDetailsResponse;
}
